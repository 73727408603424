@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
* {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  overflow-x: hidden;
}

body {
  --primary: #111111;
  --primary-rgb: 17, 17, 17;

  --active: #0074d9;
  --active-rgb: 0, 116, 217;

  --background: #ffffff;
  --background-rgb: 255, 255, 255;

  --instagram: #833AB4;
  --instagram-rgb: 131, 58, 180;

  --twitter: #1DA1F2;
  --twitter-rgb: 29, 161, 242;

  --email: #ff851b;
  --email-rgb: 255, 133, 27;

  --website: var(--active);
  --website-rgb: var(--active-rgb);

  --patreon: #ff424d;
  --patreon-rgb: 255, 66, 77;
  --patreon-black: #141518;
  --patreon-black-rgb: 20, 21, 24;

  --maxwidth: 1000px;

  overflow: hidden;
  font-family: 'Montserrat', sans-serif;
  background-color: var(--background);
  color: var(--primary);
  padding: 0 !important;
}

/* '&, &:active, &:visited, :focus': {
  color: 'var(--primary)',
  textDecoration: 'none',
  lineHeight: '1.4em',

  '& > *': {
    verticalAlign: 'bottom'
  }
},
'&:hover': {
  color: 'rgba(var(--primary-rgb), 0.9)'
} */

a,
a:active,
a:visited,
a:focus {
  color: var(--primary);
  text-decoration: none;
  line-height: 1.4em;
}
a > * {
  vertical-align: bottom;
}
a:hover {
  color: rgba(var(--primary-rgb), 0.75);
}

@media (prefers-color-scheme: dark) {
  body {
    --primary: #ffffff;
    --primary-rgb: 255, 255, 255;
    --background: #111111;
    --background-rgb: 17, 17, 17;
  }
}
body.force-light {
  --primary: #111111;
  --primary-rgb: 17, 17, 17;
  --background: #ffffff;
  --background-rgb: 255, 255, 255;
}
body.force-dark {
  --primary: #ffffff;
  --primary-rgb: 255, 255, 255;
  --background: #111111;
  --background-rgb: 17, 17, 17;
}

@media only screen and (max-width: 399px) {
  body {
    --num-cols: 2;
  }
}
@media only screen and (min-width: 400px) and (max-width: 599px) {
  body {
    --num-cols: 2; /* 3 columns looks awful */
  }
}
@media only screen and (min-width: 600px) and (max-width: 799px) {
  body {
    --num-cols: 4;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1199px) {
  body {
    --num-cols: 5;
  }
}
@media only screen and (min-width: 1200px) {
  body {
    /* --maxwidth: 1200px; */
    --num-cols: 5;
  }
}

